
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import Footer from '@/components/footer/index.vue'

export default {
    name: 'information',
    components: {
        homeTitlepc,
        Footer,
        
    },

    mounted () {
     
    },

    created(){
       if(this.active==0){
            this.daList=this.labList
       }

    },

    methods: {
        check(e){
            this.$router.push({
                path:'/wap/details',
                query:{name:e.name,type:e.type,time:e.time,source:e.source,status:e.status}

            })

        },
        changeActive(v){
            this.active=v
            if(this.active==0){
                this.daList=this.labList
            }
           if(this.active==1){
                this.daList=this.labList.filter(x=>{
                    return x.status==1 
                })
            }
            if(this.active==2){
                this.daList=this.labList.filter(x=>{
                    return x.status==2 
                })
            }
            if(this.active==3){
                this.daList=this.labList.filter(x=>{
                    return x.status==3 
                })
            }

        }
      

    },

    data () {
        return {
            config,
            active:0,
            registerList:[
                {
                    name:'全部'
                },
                {
                    name:'政策解读'
                },
                {
                    name:'版权小知识'
                },
                {
                    name:'识权动态'
                },
              

            ],
            daList:[],
            labList:[
                {
                    img:require('./images/s1.png'),
                    name:'《国家知识产权局 司法部关于加强新时代专利侵权纠纷行政裁决...',
                    source:'来源：国家知识产权局政务微信',
                    time:'发布时间：2023-09-15',
                    type:1,
                    status:1

                },
                {
                    img:require('./images/s2.png'),
                    name:'《企业知识产权合规管理体系要求》(GB/T 29490-2023)国家标准...',
                    source:'来源：国家知识产权局政务微信',
                    time:'发布时间：2023-09-05',
                    type:2,
                    status:1

                },
                {
                    img:require('./images/s3.png'),
                    name:'《专利（申请）权转让合同模板及签订指引》《专利实施许可合同...',
                    source:'来源：国家知识产权局政务微信',
                    time:'发布时间：2023-06-30',
                    type:3,
                    status:1

                },
                {
                    img:require('./images/s4.png'),
                    name:'《商品交易市场知识产权保护规范》国家标准解读',
                    source:'来源：国家知识产权局政务微信',
                    time:'发布时间：2023-02-17',
                    type:4,
                    status:1

                },
                {
                    img:require('./images/s5.png'),
                    name:'专利产品备案常见问题解答',
                    source:'来源：国家知识产权局政务微信',
                    time:'发布时间：2022-11-25',
                    type:5,
                    status:1

                },
                {
                    img:require('./images/s6.png'),
                    name:'这些版权小知识你了解吗？',
                    source:'来源：澎湃政务',
                    time:'发布时间：2020-04-23',
                    type:6,
                    status:2

                },
                {
                    img:require('./images/s7.png'),
                    name:'哪些行为是侵犯著作权的行为？',
                    source:'来源：华律网整理',
                    time:'发布时间：2023-08-08',
                    type:7,
                    status:2

                },
                {
                    img:require('./images/s8.png'),
                    name:'侵犯著作权的行为可能承担什么责任？',
                    source:'来源：找法网官方整理',
                    time:'发布时间：2023-05-30',
                    type:8,
                    status:2

                },
                {
                    img:require('./images/s9.png'),
                    name:'我国保护著作权的法律法规主要有哪些？',
                    source:'来源：法师兄（原110咨询网）',
                    time:'发布时间：2023-05-30',
                    type:9,
                    status:2

                },
                {
                    img:require('./images/s10.png'),
                    name:'著作权包括有哪些权力？',
                    source:'来源：找法网',
                    time:'发布时间：2021-10-22',
                    type:10,
                    status:2

                },
                {
                    img:require('./images/s11.png'),
                    name:'正薪旗下“识权识美”项目获评“中国人力资源服务机构十大创新案...',
                    source:'来源：搜狐网',
                    time:'发布时间：2021-04-01',
                    type:11,
                    status:3

                },
                {
                    img:require('./images/s12.png'),
                    name:'喜讯 | “识权识美”荣膺2020年度人力资源服务平台品牌创新奖！',
                    source:'来源：识权识美公众号',
                    time:'发布时间：2021-07-13',
                    type:12,
                    status:3

                },
                {
                    img:require('./images/s13.png'),
                    name:'护航知识产权，方能十全十美！',
                    source:'来源：识权识美公众号',
                    time:'发布时间：2022-10-01',
                    type:13,
                    status:3

                },
                {
                    img:require('./images/s14.png'),
                    name:'世界知识产权日 | 识权识美全面加强版权工作，推动构建新发展格局',
                    source:'来源：人是云公众号',
                    time:'发布时间：2021-04-26',
                    type:14,
                    status:3

                },
                {
                    img:require('./images/s15.png'),
                    name:'一文带您深入了解识权识美~',
                    source:'来源：识权识美公众号',
                    time:'发布时间：2021-04-28',
                    type:15,
                    status:3

                }
            ]
           
            
        }
    }
}
